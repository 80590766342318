<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />
    <Footer logged />

    <div class="card card-fixed mb-n5" data-card-height="420">
      <div
        class="card rounded-0 bg-20"
        data-card-height="450"
        :style="{ 'background-image': `url(${dataEvent.banner_image})` }"
      >
        <div class="card-bottom px-3 pb-2">
          <h1 class="color-white font-28 mb-0">{{ dataEvent.title }}</h1>
          <p class="color-white font-12 opacity-80"></p>
        </div>
        <div class="card-top mt-3 pb-5 ps-3">
          <a
            @click="$router.go(-1)"
            data-back-button
            class="icon icon-s bg-theme rounded-xl float-start me-3"
            ><i class="fa color-theme fa-arrow-left"></i
          ></a>
          <a
            href="#"
            data-menu="menu-share"
            @click="menuOpen('menu-share')"
            class="icon icon-s bg-theme rounded-xl float-end me-3"
            ><i class="fa color-theme fa-share-alt"></i
          ></a>
        </div>
        <div class="card-overlay bg-gradient"></div>
      </div>
    </div>
    <div class="card card-clear" data-card-height="400"></div>

    <div class="page-content pb-3">
      <div class="card card-full rounded-m pb-4">
        <div class="drag-line"></div>

        <div class="content pb-5">
          <div class="divider"></div>

          <h1>{{ dataEvent.title }}</h1>
          <p>
            <span v-html="dataEvent.content"></span>
          </p>
        </div>
      </div>
    </div>
    <!-- Page content ends here-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="250"
    >
      <MenuShare />
    </div>

    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import MenuShare from "@/components/menu/MenuShare.vue";
export default {
  name: "Event",
  components: {
    Header,
    Footer,
    MenuShare,
  },
  data() {
    return {
      dataEvent: [],
      isLoading: true,
    };
  },
  mounted() {
    init_template();
    this.getData();
  },
  methods: {
    menuOpen,
    menuClose,
    async getData() {
      await axios
        .get("/api/v1/article/")
        .then((response) => {
          this.dataEvent = response.data[0];
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },
  },
};
</script>
