<template>
  <div class="menu-title">
    <p class="color-highlight">Tap a link to</p>
    <h1>Share</h1>
    <a href="#" @click="menuClose" class="close-menu"
      ><i class="fa fa-times-circle"></i
    ></a>
  </div>
  <div class="divider divider-margins mt-3 mb-0"></div>
  <div class="content mt-0">
    <div class="list-group list-custom-small list-icon-0">
      <a href="auto_generated" class="external-link shareToWhatsApp">
        <i
          class="
            fab
            fa-whatsapp
            font-12
            bg-whatsapp
            color-white
            shadow-l
            rounded-s
          "
        ></i>
        <span>WhatsApp</span>
        <i class="fa fa-angle-right pr-1"></i>
      </a>
      <a href="auto_generated" class="external-link shareToMail border-0">
        <i
          class="fa fa-envelope font-12 bg-mail color-white shadow-l rounded-s"
        ></i>
        <span>Email</span>
        <i class="fa fa-angle-right pr-1"></i>
      </a>
    </div>
  </div>
</template> 

<script>
import { dataMenuLoad, menuOpen, menuClose } from "./index";
export default {
  name: "MenuShare",
  mounted() {
    dataMenuLoad();
  },
  emits: ["close"],
  methods: {
    dataMenuLoad,
    menuClose,

    menuOpen,
  },
};
</script>
